import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { VStack, Button, useToast, Text, Heading, Link } from '@chakra-ui/react'
import { PasswordInput } from '../components/PasswordInput'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import { CHECK_PASSWORD } from '../queries/check_password'

function Landing(props): ReactElement {
  const id = useParams<Record<string, string>>()

  const [password, setPassword] = useState<string>('')
  const [surveyId, setSurveyId] = useState<string>(id.id)

  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()

  const [getPassword, { loading, data, error }] = useLazyQuery(CHECK_PASSWORD)
  const checkPassword = async () => {
    await getPassword({ variables: { id: surveyId, salasana: password } })
    // if(data.checkPassword)) {
    //   // setPatteristo(surveyId)
    // } else {
    //   toast({
    //     title: intl.formatMessage({
    //       id: 'wrong_password',
    //       defaultMessage: 'Väärä salasana',
    //     }),
    //     status: 'error',
    //     duration: 5000,
    //     isClosable: true,
    //   })
    // }
  }

  useEffect(() => {
    if (id.pw) {
      setPassword(id.pw)
    }
  }, [id.pw])
  useEffect(() => {
    if (id.pw && password === id.pw) {
      checkPassword()
    }
  }, [password, id.pw])
  useEffect(() => {
    if (error) {
      toast({
        title: intl.formatMessage({
          id: 'survey_ended',
          defaultMessage: 'Kysely umpeutunut',
        }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (data?.testPassword) {
      window.localStorage.setItem('patteristo', surveyId)
      if (id.pw) {
        history.push({
          pathname: '/roleselection',
          state: {
            displayLangSwitcher: true,
          },
        })
      } else {
        history.push({
          pathname: 'roleselection',
          state: {
            displayLangSwitcher: false,
          },
        })
      }
    } else if (password.length > 0) {
      toast({
        title: intl.formatMessage({
          id: 'wrong_password',
          defaultMessage: 'Väärä salasana',
        }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line
  }, [data, error])

  function handleKeyDown(e: { key: string }) {
    if (e.key == 'Enter') {
      checkPassword()
    }
  }

  if (id.id) {
    return (
      <VStack spacing={8} pt={8}>
        {/* <LangSwitcher setLanguage={props.setLanguage} /> */}
        <Heading as="h1" fontSize="2xl">
          <FormattedMessage id="welcome" defaultMessage="Tervetuloa yhteistoimintavaakaan!" />
        </Heading>
        {/* <Text>Kyselyn tunnus: {id.id}</Text> */}
        <PasswordInput setPassword={setPassword} handleKeyDown={handleKeyDown} />
        <Button aria-label="Aloita" variant="primary" onClick={checkPassword}>
          <FormattedMessage id="start" defaultMessage="Aloita" />
        </Button>
      </VStack>
    )
  }

  return (
    <VStack textAlign="left" marginLeft={400} marginRight={400} spacing={8} pt={8}>
      <Heading>Tervetuloa yhteistoimintavaa’an käyttäjäksi!</Heading>
      <Text textAlign="left">
        Yhteistoimintavaaka on Teknologiateollisuus ry:n suunnittelema työkalu henkilöstön ja
        työnantajan välisen yhteistoiminnan tason mittaamiseen. Mittaus tehdään työnantajan
        toimesta, yhdessä henkilöstön edustajien kanssa. Vastaajaryhminä toimivat kaikki
        henkilöstöryhmät, heidän edustajansa, sekä työnantajan edustajat Teknologiateollisuuden
        sopimusaloilla.
      </Text>
      <Text textAlign="left">
        Palveluun rekisteröidytään sähköpostiosoitteella ja yrityksen Y-tunnuksella.
      </Text>
      <Text textAlign="left">
        Tämä työkalu on tarkoitettu ainoastaan Teknologiateollisuus ry:n jäsenyritysten ja niiden
        edustajien käyttöön. Rekisteröitymisen yhteydessä yrityksen jäsenyys tarkistetaan, eikä
        rekisteröitymislomakkeella pääse eteenpäin, mikäli jäsenyyttä ei löydy. Jäsenyyteen
        liittyvissä kysymyksissä auttaa{' '}
        <Link href="mailto:jasenasiat@teknologiateollisuus.fi">
          jasenasiat(at)teknologiateollisuus.fi
        </Link>
        .
      </Text>
      <Text textAlign="left">
        Lisätietoa tasomittarista{' '}
        <Link href="mailto:yhteistoimintavaaka@teknologiateollisuus.fi">
          yhteistoimintavaaka@teknologiateollisuus.fi
        </Link>
        .
      </Text>
    </VStack>
  )
}

export default Landing

import firebase from 'firebase'

//2. Initialize app with the config vars
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_TEKNOLOGIATEOLLISUUS_API_KEY,
  authDomain: 'tekno-tasomittari.firebaseapp.com',
  projectId: 'tekno-tasomittari',
  storageBucket: 'teknokyselylomake',
  messagingSenderId: '189546487572',
  appId: '1:189546487572:web:a912c1e3542c31e4d3ec01',
  measurementId: 'G-4XB80BJ1H5',
})

//3. export it for use
export default app

import { Stack } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

const TitleText = props => {
  const user = props.user
  const history = useHistory()
  return (
    <Stack
      style={{ cursor: 'pointer', marginRight: '10px' }}
      onClick={() => (user ? history.push('/admin') : history.push('/'))}
      fontWeight="semibold"
    >
      <FormattedMessage id="appTitle" defaultMessage="Yhteistoimintavaaka" />
    </Stack>
  )
}

export default TitleText

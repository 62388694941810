// TEOLLISUUSLIITTO THEME
import { theme as baseTheme } from '@chakra-ui/react'

export const colors = {
  brand: {
    black: baseTheme.colors.black,
    white: baseTheme.colors.white,
    gray: baseTheme.colors.gray,
    primary: {
      50: '#E6FFFA',
      100: '#B2F5EA',
      200: '#81E6D9',
      300: '#4FD1C5',
      400: '#38B2AC',
      500: '#319795',
      600: '#2C7A7B',
      700: '#285E61',
      800: '#234E52',
      900: '#1D4044',
    },
    secondary: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    },
    success: baseTheme.colors.green,
  },
  chart: [
    ['#e60000', '#500808'],
    ['#5b5857', '#8e8a89'],
    ['#30b6aa', '#6edad0'],
    ['#ff5733', '#ffcc33'],
    ['#ff00ff', '#660066'],
    ['#007acc', '#78c4d4'],
    ['#d1a32c', '#ffdb58'],
    ['#ff9900', '#ffdb4d'],
    ['#008080', '#00cccc'],
    ['#993366', '#ff99cc'],
    ['#ff6666', '#ffcccb'],
    ['#ff3399', '#cc0099'],
    ['#003366', '#3399cc'],
    ['#993333', '#ffcc99'],
    ['#ff6666', '#ffcccc'],
    ['#ff0033', '#cc0000'],
    ['#663300', '#996633'],
    ['#ff6600', '#ffcc00'],
    ['#ff3366', '#ff3399'],
    ['#ff0000', '#cc3333'],
    ['#ff9933', '#ffcc66'],
    ['#cc9900', '#ffcc00'],
    ['#006633', '#009933'],
    ['#0066cc', '#3399ff'],
    ['#9900cc', '#cc33ff'],
    ['#990000', '#cc3333'],
    ['#cc6633', '#ff9966'],
    ['#996600', '#cc9900'],
    ['#003300', '#006600'],
    ['#003366', '#336699'],
    ['#660099', '#9933cc'],
    ['#660000', '#993333'],
    ['#996633', '#cc9966'],
  ],
}

import { Text, VStack, Select, Button } from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { ChangeEvent, ReactElement, useEffect } from 'react'
import { useRole, Role } from '../contexts/RoleContext'
import LangSwitcher from '../components/LangSwitcher'
import { useQuery } from '@apollo/client'
import { ROLES_BY_PATTERISTO_ID } from '../queries/roles_by_patteristoId'

interface LocationState {
  displayLangSwitcher: boolean
}

function RoleSelection(props): ReactElement {
  const intl = useIntl()
  const { role, setRole } = useRole()
  const { data, refetch } = useQuery(ROLES_BY_PATTERISTO_ID, {
    variables: { id: localStorage.getItem('patteristo') },
  })
  const [roles, setRoles] = useState<Role[]>([])

  useEffect(() => {
    if (data?.rolesByPatteristoId.length > 0) {
      setRoles(
        data.rolesByPatteristoId.reduce((acc, role) => {
          if (role.subRoles.length > 0) {
            return [...acc, ...role.subRoles]
          } else {
            return [...acc, role]
          }
        }, [])
      )
    }
  }, [data])
  const history = useHistory()
  const location = useLocation<LocationState>()
  useEffect(() => {
    console.log(role)
  }, [role])

  const state = location.state as LocationState
  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    if (!event.target.value) {
      setRole({ id: '', name: '' })
      return
    }
    setRole(roles.find(role => role.id === event.target.value) as Role)
  }

  function handleKeyDown(e: { key: string }) {
    if (e.key == 'Enter') {
      history.push('/guide')
    }
  }
  useEffect(() => {
    setRole({ id: '', name: '' })
    //@ts-ignore
    // eslint-disable-next-line
  }, [])

  return (
    <VStack spacing={8} pt={8}>
      {state.displayLangSwitcher ? <LangSwitcher setLanguage={props.setLanguage} /> : null}

      <Text>
        {intl.formatMessage({
          id: 'role',
          defaultMessage: 'Valitse roolisi',
        })}
      </Text>
      <Select
        placeholder={intl.formatMessage({
          id: 'roledefault',
          defaultMessage: 'Valitse roolisi',
        })}
        onChange={e => handleChange(e)}
        onKeyDown={handleKeyDown}
        maxW="600px"
      >
        {roles.map(role => (
          <option value={role.id}>
            {intl.formatMessage({ id: role.name, defaultMessage: role.name })}
          </option>
        ))}
      </Select>
      <Button
        aria-label="Seuraava"
        isDisabled={role.id === ''}
        _hover={
          role.id === ''
            ? {
                cursor: 'not-allowed',
                opacity: '0.4',
              }
            : {}
        }
        onClick={() => {
          history.push('/guide')
        }}
        variant="primary"
      >
        <FormattedMessage id="nextQuestion" defaultMessage="Seuraava" />
      </Button>
    </VStack>
  )
}

export default RoleSelection
